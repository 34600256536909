// import React, { useEffect, useState } from 'react';
// import axios from 'axios'; // Make sure axios is imported
// import '../About/About.css';
// import { baseUrl, getAllImage } from '../../Utils/api'; // Assuming baseUrl is your API base URL

// const Gallery = () => {
//   const [images, setImages] = useState([]);

//   useEffect(() => {
//     const fetchImages = async () => {
//       try {
//         const response = await axios.get(getAllImage);
//         const imageUrls = response.data.data.map(study => `${baseUrl}${study.image}`); // Append baseUrl to image paths
//         setImages(imageUrls); // Ensure we set an array of image URLs
//       } catch (error) {
//         console.log('Error fetching data', error);
//       }
//     };

//     fetchImages();
//   }, []);

//   return (
//     <>
//       <div className='container-fluid'>
//         <div className='row'>
//           <div className='col aboutus__bg aboutus_title'>
//             <h2 className='fs-1 fw-bold text-light text-center'>Gallery</h2>
//           </div>
//         </div>

//         <div className="max-w-screen-2xl mx-auto px-4 py-16 lg:py-24 relative bg-gray-50">
//           <div className="flex flex-col md:flex-row gap-2">
//             <div className="flex flex-1 flex-col gap-2">
//               <div className="flex flex-1 flex-col">
//                 {images[0] && (
//                   <img
//                     className="object-cover h-full"
//                     src={images[0]} // Display the first image
//                     alt="Gallery Image 1"
//                   />
//                 )}
//               </div>
//               <div className="hidden md:flex flex-1 flex-row gap-2">
//                 {images.slice(1, 3).map((image, index) => (
//                   <div key={index} className="flex flex-1 flex-col">
//                     <img className="object-cover h-full" src={image} alt={`Gallery Image ${index + 2}`} />
//                   </div>
//                 ))}
//               </div>
//             </div>
//             <div className="flex flex-1 flex-col gap-2">
//               <div className="hidden md:flex flex-1 flex-row gap-2">
//                 {images.slice(3, 5).map((image, index) => (
//                   <div key={index} className="flex flex-1 flex-col">
//                     <img className="object-cover h-full" src={image} alt={`Gallery Image ${index + 4}`} />
//                   </div>
//                 ))}
//               </div>
//               <div className="flex flex-1 flex-col">
//                 {images[5] && (
//                   <img className="object-cover h-full" src={images[5]} alt="Gallery Image 6" />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-screen-2xl mx-auto px-4 py-16 lg:py-24 relative bg-white">
//           <div className="flex flex-col md:flex-row gap-2">
//             <div className="flex flex-1 flex-col">
//               <div className="flex flex-1 flex-col">
//                 {images[6] && (
//                   <img className="object-cover h-full" src={images[6]} alt="Gallery Image 7" />
//                 )}
//               </div>
//             </div>
//             <div className="flex flex-1">
//               <div className="grid grid-cols-2 gap-2">
//                 {images.slice(7, 11).map((image, index) => (
//                   <div key={index} className="flex flex-col">
//                     <img className="object-cover h-full" src={image} alt={`Gallery Image ${index + 8}`} />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Gallery;














import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import '../About/About.css';
import './Gallery.css';
import { baseUrl, getALLgallery, getAllImage } from '../../Utils/api'; // Assuming baseUrl is your API base URL

const Gallery = () => {
    const [imageUrls, setImages] = useState([]); // State to hold image URLs
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(getALLgallery);
                const imageUrls = response.data.data.map(study => `${baseUrl}${study.image}`); // Append baseUrl to image paths
                setImages(imageUrls); // Set the state with the array of image URLs
            } catch (error) {
                console.log('Error fetching data', error);
            }
        };

        fetchImages();
    }, []); // Empty dependency array means this runs once on mount

    return (
      <div className='container-fluid'>
      <div className='row '>  
        <div className='col aboutus__bg aboutus_title'>
            <h2 className='fs-1 fw-bold text-light text-center'>Our Gallery</h2>
        </div>
      </div>

        <div className="image-gallery bg-gray-200">
            {imageUrls.map((url, index) => ( // Map through the image URLs to display each image
                <img src={url} alt={`Gallery Item ${index + 1}`} key={index} className="image-gallery__item" />
            ))}
        </div>
        </div>
    );
};

export default Gallery;

