// import React, { useEffect, useState } from 'react';
// import './Blogdetails.css';
// import { useParams } from 'react-router-dom';
// import { getAllRecent, baseUrl } from '../../Utils/api';

// function RecentDetails() {
//   const [blog, setBlog] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const { title } = useParams();
//   const blogTitle = title.replace(/_/g, ' ');

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     const fetchBlog = async () => {
//       try {
//         const response = await fetch(`${baseUrl}${getAllRecent}`);
//         const data = await response.json();
//         console.log('Fetched blogs:', data.data);

//         const foundBlog = data.data.find(blog => blog.Articletitle === blogTitle);

//         if (foundBlog) {
//           setBlog(foundBlog);
//         } else {
//           console.error('Blog not found');
//           setBlog(null); // Ensure `blog` is set to null if not found
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//         setLoading(false);
//       }
//     };

//     fetchBlog();
//   }, [blogTitle]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!blog) {
//     return <div>Blog not found</div>;
//   }

//   return (
//     <div className="container-fluid">
//       <div className='row'>  
//         <div className='col blogheader__bg'>
//           <h2 className='fs-1 fw-bold text-light text-center'>{blog.Articletitle}</h2>
//         </div>
//       </div>

//       <div className="row d-flex justify-content-center p-3 shadow-2xl">
//         <div className="col-md-8 border-solid border-2 p-4 shadow-2xl rounded-lg blog__detail">
//           <div className="row">
//             <div className="col-md-10">
//               <p className="d-flex justify-content-between align-items-center">
//                 <span className="d-flex align-items-center">
//                   MDFirst Research Center
//                   <span className="d-flex align-items-center ml-2">
//                     2 Min to read
//                   </span>
//                 </span>
//               </p>
//               <h5 className="h5 p-2">{blog.Articletitle}</h5>
//             </div>
//             <div className="row d-flex justify-content-center">
//               <div className="col-md-11">
//                 <img
//                   src={`${baseUrl}${blog.image}`}
//                   className="img-fluid blog_img rounded-lg"
//                   alt={blog.Articletitle}
//                   loading="lazy"
//                   style={{ height: '400px', width: '100%' }}
//                 />
//                 <br />
//                 <p dangerouslySetInnerHTML={{ __html: blog.content }} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default RecentDetails;





import React, { useEffect, useState } from 'react';
import './Blogdetails.css';
import { useParams } from 'react-router-dom';
import { getAllRecent,baseUrl } from '../../Utils/api';

function RecentDetails() {
 
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { title } = useParams();
  const blogTitle = title.replace(/_/g, ' ');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        
        const response = await fetch(getAllRecent);
        const data = await response.json();
        console.log('Fetched blogs:', data.data);

        const foundBlog = data.data.find(blog => blog.Articletitle === blogTitle);

        if (foundBlog) {
          setBlog(foundBlog);
        } else {
          console.error('Blog not found');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogTitle]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    
    <>
   
      <div className="container-fluid">
      <div className='row '>  
    <div className='col blogheader__bg'>
        <h2 className='fs-1 fw-bold text-light text-center'>{blog.Articletitle}</h2>
    </div>
  </div>

        <div className="row d-flex justify-content-center p-3 shadow-2xl">
          <div className="col-md-8 border-solid border-2 p-4 shadow-2xl rounded-lg blog__detail">
            <div className="row">
              <div className="col-md-10">
                <p className="d-flex justify-content-between align-items-center">
                  <span className="d-flex align-items-center">
                    MDFirst Research Center
                    <span className="d-flex align-items-center ml-2">
                      {/* <IoIosTimer className="mr-1" /> */}
                      2 Min to read
                    </span>
                  </span>
                </p>
                  <h5 className="h5 p-2">{blog.Articletitle}</h5>
              </div>
             

       

              <div className="row d-flex justify-content-center">
                <div className="col-md-11">
                  <img
                  src={`${baseUrl}${blog.image}`}
                    className=" img-fluid blog_img rounded-lg"
                    alt=""
                    loading="lazy"
                    style={{ height: '400px', width: '100%' }}
                  />
                  <br />
            <p dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>

       
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentDetails;










