import React, { useState } from 'react';
import axios from 'axios';
import './style.css';
import toast, { Toaster } from 'react-hot-toast';
import { creategallery } from '../../Utils/api';
// import { createImage} from '../../Utils/api';


const AddGallery = () => {
  const [formData, setFormData] = useState({
    image: null,
  });

  const [errors, setErrors] = useState({});

  const inputHandler = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.image) newErrors.image = "Investigator image is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
     data.append('image', formData.image);

    try {
      const response = await axios.post(creategallery , data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Location image added:', response.data);
      toast.success('Gallery Tab Image Added successfully!');
     
      setFormData({
     
        image: null,
      });
      setErrors({});
    } catch (error) {
      console.error('Error adding Investigator:', error);
    }
  };

  return (
    
    <div className='dataaddform'>
    <Toaster/>
      <h1 className='formheading'>Add Gallery Tab Image Form</h1>

      <div className='row d-flex'></div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <label htmlFor="image" className="form-label">Gallery Tab Image</label>
            <input
              type="file"
              className={`form-control ${errors.image ? 'is-invalid' : ''}`}
              placeholder="Office Image"
              onChange={inputHandler}
              name="image"
              id="image"
            />
            {errors.image && <div className="invalid-feedback">{errors.image}</div>}
          </div>
        </div>

      

    
      
        <div className='row mt-3'>
          <div className='col text-end'>
            <button type="submit" className="btn btn-info">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddGallery;

