import React, { useEffect, useState } from 'react';
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl, deleteRecent, getAllRecent } from '../../Utils/api';
import toast, { Toaster } from 'react-hot-toast';

function InfoRecent() {
  const [recentdata, setRecentdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(getAllRecent);
        console.log(response);
        setRecentdata(response.data.data);
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, []);

  const deleteUser = async (blogId) => {
    // if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        await axios.delete(`${deleteRecent}${blogId}`);
        setRecentdata(prevData => prevData.filter(blog => blog._id !== blogId));
        toast.success('Recent News Deleted successfully!');

      } catch (error) {
        setError("Error deleting data");
        console.error("Error deleting data", error);
      }

    // }
  };

  const truncateText = (text, maxLength) => {
    return text.length <= maxLength ? text : text.substring(0, maxLength) + '...';
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="alert alert-danger text-center">{error}</div>;

  return (
    <div className="container-fluid table-responsive py-5">
    <Toaster/>
    
      <h1 className='text-center fs-3 fw-bold mb-3'>Recent Article Information</h1>
      {recentdata.length === 0 ? (
        <div className="text-center">No recent articles available.</div>
      ) : (
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Category</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {recentdata.map((blog, index) => (
              <tr key={blog._id}>
                <th scope="row">{index + 1}</th>
                <td>{blog.category}</td>
                <td>{blog.Articletitle}</td>
                <td>
                  <p dangerouslySetInnerHTML={{ __html: truncateText(blog.content, 100) }} />
                </td>
                <td>
                  <img src={`${baseUrl}${blog.image}`} alt="Blog" style={{ width: '50px', height: '50px' }} />
                </td>
                <td>
                  <Link to={`/UpdateRecent/${blog._id}`} className="me-3">
                    <FaEdit />
                  </Link>
                  <MdDelete
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteUser(blog._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default InfoRecent;
