import React, { useRef, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import JoditEditor from 'jodit-react';
import { createRecent } from '../../Utils/api';
import './style.css';

const AddRecent = () => {
    const editor = useRef(null);
    const [formData, setFormData] = useState({
        category: "",
        Articletitle: "",
        content: "",
        image: null,
    });
    const [errors, setErrors] = useState({});

    const config = {
        readonly: false,
        height: 400,
        buttons: [
            'bold', 'italic', 'underline', 'ul', 'ol', 'image', 'link', 'source',
        ],
    };

    const inputHandler = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.category) newErrors.category = "Category is required";
        if (!formData.Articletitle) newErrors.Articletitle = "Title is required";
        if (!formData.content) newErrors.content = "Content is required";
        if (!formData.image) newErrors.image = "Image is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const data = new FormData();
        data.append('category', formData.category);
        data.append('Articletitle', formData.Articletitle);
        data.append('content', formData.content);
        data.append('image', formData.image);

        try {
            const response = await axios.post(createRecent, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('Recent article submitted successfully!');
            setFormData({
                category: "",
                Articletitle: "",
                content: "",
                image: null,
            });
            setErrors({});
        } catch (error) {
            toast.error('Failed to submit article.');
            console.error('Error submitting article:', error);
        }
    };

    return (
        <div className="dataaddform">
            <Toaster />
            <h1 className="formheading">Add Recent Article</h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <label htmlFor="image" className="form-label">Image</label>
                        <input
                            type="file"
                            className={`form-control ${errors.image ? 'is-invalid' : ''}`}
                            onChange={inputHandler}
                            name="image"
                            id="image"
                        />
                        {errors.image && <div className="invalid-feedback">{errors.image}</div>}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col">
                        <label htmlFor="category" className="form-label">Category</label>
                        <input
                            type="text"
                            className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                            onChange={inputHandler}
                            id="category"
                            name="category"
                            value={formData.category}
                        />
                        {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                    </div>
                    <div className="col">
                        <label htmlFor="Articletitle" className="form-label">Title</label>
                        <input
                            type="text"
                            className={`form-control ${errors.Articletitle ? 'is-invalid' : ''}`}
                            onChange={inputHandler}
                            id="Articletitle"
                            name="Articletitle"
                            value={formData.Articletitle}
                        />
                        {errors.Articletitle && <div className="invalid-feedback">{errors.Articletitle}</div>}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col">
                        <JoditEditor
                            ref={editor}
                            value={formData.content}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setFormData({ ...formData, content: newContent })}
                        />
                        {errors.content && <div className="text-danger">{errors.content}</div>}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col text-end">
                        <button type="submit" className="btn btn-info">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddRecent;
























