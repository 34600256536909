// import React, { useEffect, useState } from 'react';
// import { baseUrl, getAllEvents } from '../../Utils/api';
// import axios from 'axios'; 
// import { toast } from 'react-hot-toast'; 
// import { Link } from 'react-router-dom';

// function UpcomingEvents() {
//   const [events, setEvents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     window.scrollTo(0, 0);
// }, []);


//   useEffect(() => {
//     const fetchEvents = async () => {
//       setLoading(true);
//       console.log('Start fetching events at:', new Date().toISOString());
//       try {
//         const response = await axios.get(getAllEvents);
//         console.log('API Response:', response.data);
//         console.log('Data fetched at:', new Date().toISOString());
  
//         if (Array.isArray(response.data) || Array.isArray(response.data.data)) {
//           setEvents(response.data.data || response.data);
//         } else {
//           console.log('Unexpected data format:', response.data);
//           toast.error('Unexpected data format!');
//         }
//       } catch (error) {
//         console.log("Error fetching data", error);
//         setError('Error fetching events data!');
//         toast.error('Error fetching events data!');
//       } finally {
//         setLoading(false);
//         console.log('Finished fetching events at:', new Date().toISOString());
//       }
//     };
  
//     fetchEvents();
//   }, []);
  
  

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
//     return date.toLocaleDateString('en-US', options).replace(/\//g, '-'); // Change '/' to '-'
//   };

//   const truncateContent = (content, wordLimit) => {
//     const words = content.split(' ');
//     return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : content;
//   };

//   if (loading) {
//     return <div className='text-center'>Loading events...</div>;
//   }

//   if (error) {
//     return <div className='text-center text-red-500'>{error}</div>;
//   }

//   return (
   
  
//     <div className='container-fluid'>
//     <div className='row'>
//       <div className='col aboutus__bg aboutus_title'>
//         <h2 className='fs-1 fw-bold text-light text-center'>Upcoming Events</h2>
//       </div>
//     </div>
  
//     <div className="px-4 py-10 sm:px-6 sm:py-14 lg:px-8 lg:py-20 font-ibmplex font-regular text-slate-800 bg-gray-200">
//       <section className="flex flex-col mx-auto w-full max-w-7xl">
//         {events.slice().reverse().map((event) => (
//           <div key={event.id} className="my-4 flex flex-col md:flex-row items-center">
//             <div className="flex flex-col md:flex-row p-4 bg-slate-50 rounded-xl shadow-md shadow-slate-200/40 hover:shadow-lg hover:shadow-slate-400/40 transition-shadow w-full">
//               <div className="flex flex-col items-center justify-center w-full md:w-48 h-36 px-5 py-5 font-merriweather bg-red-900 text-white rounded-lg mb-4 md:mb-0">
//                 <div className="text-sm font-bold font-ibmplex">{formatDate(event.startDate)}</div>
//                 <div className="text-3xl font-bold">{event.time}</div>
//               </div>
//               <div className="flex flex-col justify-center pl-0 md:pl-6 w-full">
//                 <h2 className="font-merriweather font-black text-lg md:text-xl pb-1">{event.name}</h2>
//                 <p className="text-sm md:text-base">{truncateContent(event.content, 30)}</p>
//                 <p className="font-bold text-sm md:text-base">Contact: {event.contactInfo}</p>
//                 <p className="font-bold text-sm md:text-base">Location: {event.address}</p>
                
//                 <Link 
//                   to={`/eventdetail/${event._id}`} 
//                   className="text-red-900 underline text-sm mt-2"
//                 >
//                   <h6 className="h-6 text-red-900 fw-bold">Read More</h6>
//                 </Link>
//               </div>
//               <div className="flex flex-col items-center justify-center w-full md:w-auto mt-4 md:mt-0 rounded-lg">
//                 <img
//                   src={baseUrl + event.image}
//                   className="w-full h-36 md:w-44 rounded-md shadow-md"
//                   alt={event.title} 
//                 />
//               </div>
//             </div>
//           </div>
//         ))}
//       </section>
//     </div>
//   </div>
  
//   );
// }

// export default UpcomingEvents;





















import React, { useEffect, useState } from 'react';
import { baseUrl, getAllEvents } from '../../Utils/api';
import axios from 'axios'; 
import { toast } from 'react-hot-toast'; 
import { Link } from 'react-router-dom';

function UpcomingEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      console.log('Start fetching events at:', new Date().toISOString());
      try {
        const response = await axios.get(getAllEvents);
        console.log('API Response:', response.data);
        console.log('Data fetched at:', new Date().toISOString());
  
        if (Array.isArray(response.data) || Array.isArray(response.data.data)) {
          setEvents(response.data.data || response.data);
        } else {
          console.log('Unexpected data format:', response.data);
          toast.error('Unexpected data format!');
        }
      } catch (error) {
        console.log("Error fetching data", error);
        setError('Error fetching events data!');
        toast.error('Error fetching events data!');
      } finally {
        setLoading(false);
        console.log('Finished fetching events at:', new Date().toISOString());
      }
    };
  
    fetchEvents();
  }, []);
  
  
  // Helper function to format time (12-hour AM/PM format)
  function formatTime(time) {
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
  }

  // Helper function to format phone numbers (XXX-XXX-XXXX format)
  function formatPhoneNumber(number) {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return number; // Return the original number if it doesn't match the format
  }


  const truncateContent = (content, wordLimit) => {
    const words = content.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : content;
  };

  if (loading) {
    return <div className='text-center'>Loading events...</div>;
  }

  if (error) {
    return <div className='text-center text-red-500'>{error}</div>;
  }

  return (
   
  
    <div className='container-fluid'>
    <div className='row'>
      <div className='col aboutus__bg aboutus_title'>
        <h2 className='fs-1 fw-bold text-light text-center'>Upcoming Events</h2>
      </div>
    </div>
  
    <div className="px-4 py-10 sm:px-6 sm:py-14 lg:px-8 lg:py-20 font-ibmplex font-regular text-slate-800 bg-gray-200">
      <section className="flex flex-col mx-auto w-full max-w-7xl">
        {events.slice().reverse().map((event) => (
          <div key={event.id} className="my-4 flex flex-col md:flex-row items-center">
            <div className="flex flex-col md:flex-row p-4 bg-slate-50 rounded-xl shadow-md shadow-slate-200/40 hover:shadow-lg hover:shadow-slate-400/40 transition-shadow w-full">
              <div className="flex flex-col items-center justify-center w-full md:w-48 h-36 px-5 py-5 font-merriweather bg-red-900 text-white rounded-lg mb-4 md:mb-0">
                <div className="text-sm font-bold font-ibmplex">  {new Date(event.startDate).toLocaleDateString()} </div>
                <div className="text-3xl font-bold">{formatTime(event.time)}</div>
              </div>
              <div className="flex flex-col justify-center pl-0 md:pl-6 w-full">
                <h2 className="font-merriweather font-black text-lg md:text-xl pb-1 text-red-900">{event.name}</h2>
                <p className="text-sm md:text-base">{truncateContent(event.content, 30)}</p>
                <p className="font-bold text-sm md:text-base">Contact: {formatPhoneNumber(event.contactInfo)}</p>
                <p className="font-bold text-sm md:text-base">Location: {event.address}</p>
                
                <Link 
                  to={`/eventdetail/${event._id}`} 
                  className="text-red-900 underline text-sm mt-2 text-center"
                >
                  <h6 className="h-6 text-red-900 fw-bold">Read More</h6>
                </Link>
              </div>
              <div className="flex flex-col items-center justify-center w-full md:w-auto mt-4 md:mt-0 rounded-lg">
                <img
                  src={baseUrl + event.image}
                  className="w-full h-36 md:w-44 rounded-md shadow-md"
                  alt={event.title} 
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  </div>
  
  );
}

export default UpcomingEvents;





















