// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
// import './style.css';
// import { baseUrl, getRecentById, updateRecent } from '../../Utils/api';
// import toast, { Toaster } from 'react-hot-toast';

// const UpdateRecent = () => {
//   const { id } = useParams(); // Use useParams to get the id from the URL

//   const [formData, setFormData] = useState({
//     category: "",
//     Articletitle: "",
//     blogdesc: "",
//     image: null
//   });

//   const [isLoading, setIsLoading] = useState(true); // Add a loading state
//   const [existingImage, setExistingImage] = useState(null);
//   const [error, setError] = useState(null); // Add an error state
//   const [success, setSuccess] = useState(null); // Add a success state

//   useEffect(() => {
//     const fetchBlogData = async () => {
//       try {
//         const response = await axios.get(`${getRecentById}/${id}`);
//         const data = response.data.data; // Access the data property of the response
//         setFormData({
//           category: data.category,
//           Articletitle: data.Articletitle,
//           blogdesc: data.blogdesc,
//           image: null // Use null if image is undefined
//         });
//         setExistingImage(data.image); // Set existing image URL
//         setIsLoading(false);
//       } catch (error) {
//         setError('Error fetching recent data.');
//         console.error('Error fetching recent data:', error);
//         setIsLoading(false);
//       }
//     };

//     fetchBlogData();
//   }, [id]);

//   const inputHandler = (e) => {
//     const { name, value, files } = e.target;
//     setFormData(prevFormData => ({
//       ...prevFormData,
//       [name]: files ? files[0] : value
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     data.append('category', formData.category);
//     data.append('Articletitle', formData.Articletitle);
//     data.append('blogdesc', formData.blogdesc);
//     if (formData.image) {
//       data.append('image', formData.image);
//     }

//     try {
//       await axios.patch(`${updateRecent}/${id}`, data, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });
//       setSuccess('Recent updated successfully.');
//       toast.success('Recent Article Update successfully!');
//       setError(null); // Clear any previous errors
//     } catch (error) {
//       setError('Error updating recent.');
      
//       console.error('Error updating recent:', error);
//     }
//   };

//   if (isLoading) {
//     return <div>Loading...</div>; // Display loading state
//   }

//   return (
//     <div className='dataaddform'>
//       <h1 className='formheading'>Update Recent Form</h1>

//       {error && <div className="alert alert-danger">{error}</div>}
//       {success && <div className="alert alert-success">{success}</div>}

//       <form onSubmit={handleSubmit}>
//         <div className='row'>
//           <div className='col'>
//             <label htmlFor='image' className='form-label'>Recent Image</label>
//             {existingImage && (
//               <div>
//                 <img src={`${baseUrl}${existingImage}`} alt="Current Recent" style={{ width: '200px', height: 'auto' }} />
//               </div>
//             )}
//             <input type="file" className="form-control" onChange={inputHandler} name="image" id="image" />
//           </div>
//         </div>

//         <div className='row mt-4'>
//           <div className="col">
//             <label htmlFor="category" className="form-label">Recent Category</label>
//             <input type="text" className="form-control" onChange={inputHandler} id="category" name="category" value={formData.category} />
//           </div>
//           <div className="col">
//             <label htmlFor="Articletitle" className="form-label">Recent Title</label>
//             <input type="text" className="form-control" onChange={inputHandler} id="Articletitle" name="Articletitle" value={formData.Articletitle} />
//           </div>
//         </div>

//         <div className='row mt-4'>
//           <div className="col">
//             <label htmlFor="blogdesc" className="form-label">Recent Description</label>
//             <textarea className="form-control" id="blogdesc" name="blogdesc" rows="3" onChange={inputHandler} value={formData.blogdesc}></textarea>
//           </div>
//         </div>

//         <div className='row mt-3'>
//           <div className='col text-end'>
//             <button type="submit" className="btn btn-info">Update</button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default UpdateRecent;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl, getRecentById, updateRecent } from '../../Utils/api';
import './style.css';

const UpdateRecent = () => {
  const { id } = useParams();
  const editor = useRef(null);

  const [formData, setFormData] = useState({
    category: "",
    Articletitle: "",
    blogdesc: "",
    image: null
  });

  const [isLoading, setIsLoading] = useState(true);
  const [existingImage, setExistingImage] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const config = {
    readonly: false,
    height: 400,
    buttons: [
      'bold', 'italic', 'underline', 'ul', 'ol', 'image', 'link', 'source',
    ],
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${getRecentById}/${id}`);
        const data = response.data.data;
        setFormData({
          category: data.category,
          Articletitle: data.Articletitle,
          blogdesc: data.content,
          image: null
        });
        setExistingImage(data.image);
        setIsLoading(false);
      } catch (error) {
        setError('Error fetching recent data.');
        console.error('Error fetching recent data:', error);
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [id]);

  const inputHandler = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files ? files[0] : value
    }));
  };

  const handleEditorChange = (content) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      blogdesc: content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('category', formData.category);
    data.append('Articletitle', formData.Articletitle);
    data.append('content', formData.blogdesc); // Ensure content is included
    if (formData.image) {
      data.append('image', formData.image);
    }

    try {
      await axios.put(`${updateRecent}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSuccess('Recent Article updated successfully!');
      toast.success('Recent Article updated successfully!');
      setError(null); 
    } catch (error) {
      setError('Error updating recent.');
      console.error('Error updating recent:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='dataaddform'>
      <Toaster />
      <h1 className='formheading'>Update Recent Form</h1>

      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}

      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col'>
            <label htmlFor='image' className='form-label'>Recent Image</label>
            {existingImage && (
              <div>
                <img src={`${baseUrl}${existingImage}`} alt="Current Recent" style={{ width: '200px', height: 'auto' }} />
              </div>
            )}
            <input type="file" className="form-control" onChange={inputHandler} name="image" id="image" />
          </div>
        </div>

        <div className='row mt-4'>
          <div className="col">
            <label htmlFor="category" className="form-label">Recent Category</label>
            <input type="text" className="form-control" onChange={inputHandler} id="category" name="category" value={formData.category} />
          </div>
          <div className="col">
            <label htmlFor="Articletitle" className="form-label">Recent Title</label>
            <input type="text" className="form-control" onChange={inputHandler} id="Articletitle" name="Articletitle" value={formData.Articletitle} />
          </div>
        </div>

        <div className='row mt-4'>
          <div className="col">
            <label htmlFor="blogdesc" className="form-label">Recent Description</label>
            <JoditEditor
              ref={editor}
              value={formData.blogdesc}
              config={config}
              tabIndex={1}
              onChange={handleEditorChange}
            />
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col text-end'>
            <button type="submit" className="btn btn-info">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateRecent;





