import React , { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import {Link} from 'react-router-dom'
import axios from 'axios';
import {baseUrl, getAllImage,deleteImage,updateImage, getALLgallery, deletegalleryBYID } from '../../Utils/api';
import toast, { Toaster } from 'react-hot-toast';

function InfoGallery() {

  const [investigator, setInvestigator] = useState([]);

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        const response = await axios.get(getALLgallery);
        setInvestigator(response.data.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchStudies();
  }, [investigator]);


  const deleteUser = async (Id)=>{
    // console.log(blogId);deleteImage+Id
        await axios.delete(deletegalleryBYID+Id)
      .then((responses)=>{
          setInvestigator((prevUser)=> prevUser.filter((investigator)=> investigator._id !== Id))
          toast.success('Gallery Tab Image Deleted successfully!');

        })
      .catch((error) =>{
          console.log(error);
      })

    }

  return (
    <>

<div class="container-fluid table-responsive py-5"> 
  <Toaster/>
<h1 className='text-center fs-3 fw-bold mb-3'>Gallery Tab Image</h1>
<table class="table table-bordered table-hover">
 
 

  <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
     
      <th scope="col">Image</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {investigator.map((investigator, index) => (
    <tr>
      <th scope="row">{index+1}</th>
       <td><img src={baseUrl+investigator.image} alt="Location" style={{ width: '50px', height: '50px' }} /></td>
      <td>
        {/* <Link to={`/Update Investigators/${investigator._id}`}>
          <span style={{ display: 'inline-block' }}><FaEdit /></span>
        </Link> */}
        <span style={{ display: 'inline-block', marginRight: '10px' }}>
          <MdDelete onClick={() => deleteUser(investigator._id)} />
        </span>
      </td>
    </tr>
))} 
    
   
  </tbody>
</table>
</div>

    </>
  )
}

export default InfoGallery