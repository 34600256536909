import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl, getEventById } from '../../Utils/api'; // Make sure this is a valid API URL
import './EventDetails.css';

function EventDetails() {
    const { id } = useParams();
    const [event, setEvent] = useState(null); // Start with null to indicate loading state
    // console.log('Event ID:', id);

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${getEventById}/${id}`);
                // Log the entire response to check the structure
                // console.log('Full API response:', response.data);
                
                // Assuming response.data contains the event object directly
                setEvent(response.data); // Update this line if the structure differs
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    // Check if event data is available before rendering
    if (!event) {
        return <div>Loading...</div>; // Show loading state while fetching
    }

    // Log the event data after ensuring it's set
    console.log('Event data:', event);

    return (
        <div className="container-fluid">
        <div className='row '>  
      <div className='col blogheader__bg'>
          <h2 className='fs-1 fw-bold text-light text-center'>{event.name}</h2>
      </div>
    </div>
  
          <div className="row d-flex justify-content-center p-3 shadow-2xl">
            <div className="col-md-8 border-solid border-2 p-4 shadow-2xl rounded-lg blog__detail">
              <div className="row">
                <div className="col-md-10">
                  <p className="d-flex justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
                      MDFirst Research Center
                      <span className="d-flex align-items-center ml-2">
                        {/* <IoIosTimer className="mr-1" /> */}
                        2 Min to read
                      </span>
                    </span>
                  </p>
                    <h5 className="h5 text-red-900">{event.name}</h5>
                </div>
               
  
  <div className='row'>
    <div className='col-md-8 flex items-center'><p className="fs-6 text-base/7">{event.content}</p></div>
    <div className='col-md-4'><img
                    src={`${baseUrl}${event.image}`}
                      className=" img-fluid blog_img rounded-lg"
                      alt=""
                      loading="lazy"
                      style={{ height: '400px', width: '100%' }}
                    /></div>
  </div>
         
  
        
              </div>
            </div>
          </div>
        </div>
    );
}

export default EventDetails;
