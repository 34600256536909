export const baseUrl = `https://www.mdfirstresearch.com/`


export const addstudies  = " https://www.mdfirstresearch.com/studiesForm/createForm";
export const signup = " https://www.mdfirstresearch.com/login/signup";
export const login =" https://www.mdfirstresearch.com/login/login";
export const createBlog =" https://www.mdfirstresearch.com/blog/createBlog";
export const getAllBlog =" https://www.mdfirstresearch.com/blog/getAllBlog";
export const getBlogById =` https://www.mdfirstresearch.com/blog/getBlogById`;
export const updateBlog =` https://www.mdfirstresearch.com/blog/updateBlog`;
export const deleteBlog =` https://www.mdfirstresearch.com/blog/deleteBlog/`;
export const updateStudies =` https://www.mdfirstresearch.com/studiesForm/updateForm`;
export const deleteStudies =` https://www.mdfirstresearch.com/studiesForm/deleteForm/`;
export const createLocation =` https://www.mdfirstresearch.com/location/createLocation`;
export const getAllLocation =` https://www.mdfirstresearch.com/location/getAllLocation`;
export const getLocationById =` https://www.mdfirstresearch.com/location/getLocationById`;
export const updateLocation =` https://www.mdfirstresearch.com/location/updateLocation`;
export const deleteLocation =` https://www.mdfirstresearch.com/location/deleteLocation/`;
export const getAllStudies =` https://www.mdfirstresearch.com/studiesForm/getAllStudies`;
export const getStudiesById =` https://www.mdfirstresearch.com/studiesForm/getStudiesById`;
export const contactUs =` https://www.mdfirstresearch.com/contact/contact`;

export const addEvent = " https://www.mdfirstresearch.com/event/createEvent";
export const getAllEvents = ` https://www.mdfirstresearch.com/event/getAllEvents`; // Endpoint to get all events
export const deleteEvent = ` https://www.mdfirstresearch.com/event/deleteEvent/`; // Endpoint to delete an event
export const getEventById = `https://www.mdfirstresearch.com/event/getEventById`;
export const updateEvent = ` https://www.mdfirstresearch.com/event/updateEvent`; 
// export const updateEvent = `http://localhost:3000/event/updateEvent`;
// http://localhost:3000/event/deleteEvent/${id}

export const TotalStudies =  " https://www.mdfirstresearch.com/api/total-studies"

export const TotalBlog =  " https://www.mdfirstresearch.com/api/total-blogs"

export const TotaInvestigator = "https://www.mdfirstresearch.com/api/total-investigator"
export const TotaOffice = "https://www.mdfirstresearch.com/api/total-officeimage"
export const TotaEvent = "https://www.mdfirstresearch.com/api/total-event"
export const TotaGallery = "https://www.mdfirstresearch.com/api/total-gallery"


export const createInvestigator=  " https://www.mdfirstresearch.com/Investigator/createInvestigator"
export const getAllInvestigator = " https://www.mdfirstresearch.com/Investigator/getAllInvestigator"
export const deleteInvestigator = " https://www.mdfirstresearch.com/Investigator/deleteInvestigator/"
export const updateInvestigator = " https://www.mdfirstresearch.com/Investigator/updateInvestigator/"
export const getInvestigatorById = " https://www.mdfirstresearch.com/Investigator/getInvestigatorById/"


export const createImage = " https://www.mdfirstresearch.com/Image/createImage"
export const getAllImage = " https://www.mdfirstresearch.com/Image/getAllImage"
export const getImageBYID = " https://www.mdfirstresearch.com/Image/getImageBYID/"
export const deleteImage = " https://www.mdfirstresearch.com/Image/deleteImage/"
export const  updateImage = " https://www.mdfirstresearch.com/Image/updateImage/"


export const createRecent =" https://www.mdfirstresearch.com/recent/createRecent"
export const getAllRecent =" https://www.mdfirstresearch.com/recent/getAllRecent"
export const getRecentById =" https://www.mdfirstresearch.com/recent/getRecentById"
export const updateRecent =" https://www.mdfirstresearch.com/recent/updateRecent"
export const deleteRecent =" https://www.mdfirstresearch.com/recent/deleteRecent/"


export const studiesContact = " https://www.mdfirstresearch.com/studies/studies"
export const LocationContact = " https://www.mdfirstresearch.com/location/contact"
export const sponsorsContact = " https://www.mdfirstresearch.com/sponsors/sponsors-cro"
export const referralContact = " https://www.mdfirstresearch.com/referral/referrals"



export const creategallery = "https://www.mdfirstresearch.com/gallery/createImage"
export const getALLgallery = "https://www.mdfirstresearch.com/gallery/getAllImage"
export const deletegalleryBYID = "https://www.mdfirstresearch.com/gallery/deleteImage/"

